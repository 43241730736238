import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserQRCodes } from "../api/qrcode";
import Header from "./header";
import { checkLogin, getTexts } from "../api/auth";
import parse from "html-react-parser";
import LongBanner from "./componets/longBanner";
import ShortBanner from "./componets/shortBanner";

const BarcodeLists = () => {
  const [barcodeList, setBarcodeList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [bannerText, setBannerText] = useState("");

  // get banner text
  useEffect(() => {
    textDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const textDetails = async () => {
    try {
      const { data } = await getTexts();
      if (data?.bannerTexts) {
        setBannerText(data?.bannerTexts);
      }
    } catch (error) {
      console.log("Error while calling api ", error);
    }
  };
  let topAddBannerText = bannerText[0]?.topBannerText;

  let bottomAddBannerText = bannerText[0]?.bottomBannerText;

  let sideAddBannerText = bannerText[0]?.sideBannerText;

  useEffect(() => {
    setProcessing(true);
    const fetchQRCodes = async () => {
      try {
        const user = await checkLogin();
        const userId = user?.data?.user?._id;
        const { data } = await getUserQRCodes(userId);
        if (data?.barcodes) {
          setBarcodeList(data?.barcodes);
        }
      } catch (error) {
        console.log("Error while fetching barcodes: ", error?.message);
      } finally {
        setProcessing(false);
      }
    };

    fetchQRCodes();
  }, []);

  return (
    <>
      {/* Advertisement banner */}
      <LongBanner content={parse(topAddBannerText || "")} />
      <Header />
      <div className="py-10 px-10 lg:py-16 lg:px-16 max-w-[1400px] w-full mx-auto ">
        <h2 className="text-3xl font-medium mb-5">My QR Codes:</h2>
        <div className="inline-block mg:flex gap-10">
          <div className="max-w-[900px] w-full">
            <div className="">
              {barcodeList?.map((item, i) => {
                const createdAtDate = new Date(item?.createdAt);
                const formattedCreatedAt = createdAtDate
                  ?.toLocaleDateString("en-GB", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "2-digit",
                  })
                  ?.replace(/\//g, "/");
                return (
                  <div
                    key={i}
                    className="rounded-lg border border-gray-400 bg-white w-full px-3 sm:px-6 py-3 mb-3"
                  >
                    <div className="flex items-center">
                      <h4
                        className={`text-lg font-medium mr-2 ${
                          item?.name ? "mb-2" : ""
                        }`}
                      >
                        {item?.name || ""}
                      </h4>
                    </div>
                    <div className="grid xs:gap-3 xs:grid-cols-3">
                      <div className="flex xs:col-span-2">
                        <img
                          src={item?.imageLink}
                          alt=""
                          className="max-w-[130px] xs:max-w-[150px] w-full mr-3"
                        />
                        <ul>
                          <li className="tex-base mb-2 xs:mb-3">
                            {item?.link}
                          </li>
                          <li className="mb-2 xs:mb-3 text-gray-500">
                            Type:{" "}
                            <span className="text-black capitalize">
                              {
                                item?.storedInfo?.infoType === "images" ? "Image/Videos" : item?.storedInfo?.infoType === "memories" ? "Page" : item?.storedInfo?.infoType
                              }
                            </span>
                          </li>
                          <li className="mb-2 xs:mb-3 text-gray-500">
                            Created:{" "}
                            <span className="text-black">
                              {formattedCreatedAt}
                            </span>
                          </li>
                          <li className="text-gray-900">
                            <Link
                              to={`/edit/${item?._id}`}
                              className="px-3 py-1 hover:bg-tealBlue border border-gray-400 rounded-lg bg-lightBlue hover:text-white transition-all duration-500"
                            >
                              Edit QR
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="border-l border-gray-400 pl-4 hidden xs:inline-block">
                        <div className="mb-5">
                          <p className="text-2xl font-medium">
                            {" "}
                            {item?.scanCount || 0}
                          </p>{" "}
                          <span className="text-base block text-gray-500">
                            Scans
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center border-t w-full border-gray-400 mt-3 xs:hidden">
                      <div className="mr-10 mt-1 flex items-center">
                        <p className="text-2xl font-medium mr-2">
                          {" "}
                          {item?.scanCount || 0}
                        </p>{" "}
                        <span className="text-base block text-gray-500">
                          Scans
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mg:max-w-[300px] h-full">
            <ShortBanner content={parse(sideAddBannerText || "")} />
          </div>
        </div>
      </div>
      {/* Advertisement banner */}
      <LongBanner content={parse(bottomAddBannerText || "")} />
      {processing ? (
        <div>
          <div
            className="inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] absolute top-[50%] left-[50%]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
          <div className="bg-black opacity-40 absolute right-0 left-0 top-0 bottom-0"></div>
        </div>
      ) : null}
    </>
  );
};

export default BarcodeLists;
