import { useEffect, useState } from "react";
import { FaLink } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { IoImage } from "react-icons/io5";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-phone-number-input/style.css";
import toast from "react-hot-toast";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import Header from "./header";
import {
  getPrivateQRCodes,
  updateUserQRCodes,
} from "../api/qrcode";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { TbGrave2 } from "react-icons/tb";
import Memories from "./componets/Memories";
import ImageVideos from "./componets/ImageVideos";
import Pdf from "./componets/Pdf";
import { uploadToS3 } from "../utils/uploadToS3";

const schema = (activeTab) => {
  switch (activeTab) {
    case 1:
      return yup.object({
        link: yup
          .string()
          .matches(
            /^(?:(?:https?:\/\/)?[^\s"']+\.([^.\s"']{2,}|.{2,5})|[^.\s"']{2,}\.[^.\s"']{2,})?$/,
            "Invalid URL format"
          )
          .nullable(),
        name: yup.string("Name must be string"),
      });
    case 2:
      return yup.object({
        contactNum: yup
          .string()
          .test("is-valid", "Invalid phone number", (value) => {
            if (!value) return true;
            return isValidPhoneNumber(value);
          })
          .nullable(),
        name: yup.string("Name must be string"),
      });
    case 3:
      return yup.object().shape({
        title: yup.string(),
        freeText: yup.string(),
        videoUrl: yup
          .string()
          .matches(
            /^(?:(?:https?:\/\/)?[^\s"']+\.([^.\s"']{2,}|.{2,5})|[^.\s"']{2,}\.[^.\s"']{2,})?$/,
            "Invalid URL format"
          )
          .nullable(),
        description: yup.string().max(4000, "Description is too long"),
        imageText: yup
          .string()
          .max(4000, "Image gallery description is too long"),
        pdfTitle: yup.string(),
        isProtected: yup.boolean(),
        password: yup.string().when("isProtected", {
          is: true,
          then: () => yup
            .string()
            .min(6, "Password must be at least 6 characters long")
            .max(16, "Password must be at most 16 characters long")
            .required("Password is required"),
          otherwise: () => yup.string().nullable(),
        }),
      });
    case 4:
      return yup.object({
        name: yup.string(),
      });
    case 5:
      return yup.object({
        name: yup.string(),
      });
    default:
      return yup.object();
  }
};

const EditQr = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [barcodeData, setBarcodeData] = useState(null);

  // tab
  const changeTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  // form
  const methods = useForm({
    resolver: yupResolver(schema(activeTab)),
    defaultValues: {
      link: null,
      name: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = methods;
  

  const onSubmit = async (data) => {
    setProcessing(true);
    let obj = {
      name: data.name,
      type: tabMapper[activeTab],
    };
  
    try {
      if (tabMapper[activeTab] === "memories") {
        obj["memories"] = {
          title: data.title,
          freeText: data.freeText,
          videoUrl: data.videoUrl,
          videoDescription: data.videoDescription,
          imageText: data.imageText,
          pdfTitle: data.pdfTitle,
          isProtected: data.isProtected,
          password: data.isProtected ? data.password : null,
        };
  
        const uploadPromises = [];
  
        // Videos
        if (data.videos?.length) {
          const newVideos = data.videos.filter((ele) => ele instanceof File);
          const preVideos = data.videos.filter((ele) => typeof ele === "string");
          if (newVideos.length > 0) {
            uploadPromises.push(uploadToS3(newVideos, "memories", data?._id).then(result => ({type: 'videos', result})));
          }
          obj["memories"]["videos"] = preVideos;
        } else {
          obj["memories"]["videos"] = [];
        }
  
        // Images
        if (data.images?.length) {
          const newImages = data.images.filter((ele) => ele instanceof File);
          const preImages = data.images.filter((ele) => typeof ele === "string");
          if (newImages.length > 0) {
            uploadPromises.push(uploadToS3(newImages, "memories", data?._id).then(result => ({type: 'images', result})));
          }
          obj["memories"]["images"] = preImages;
        } else {
          obj["memories"]["images"] = [];
        }
  
        // PDFs
        if (data.pdfs?.length) {
          const newPdfs = data.pdfs.filter((ele) => ele instanceof File);
          const prePdfs = data.pdfs.filter((ele) => typeof ele === "string");
          if (newPdfs.length > 0) {
            uploadPromises.push(uploadToS3(newPdfs, "memories", data?._id).then(result => ({type: 'pdfs', result})));
          }
          obj["memories"]["pdfs"] = prePdfs;
        } else {
          obj["memories"]["pdfs"] = [];
        }
  
        const uploadResults = await Promise.all(uploadPromises);
  
        uploadResults.forEach(({type, result}) => {
          obj["memories"][type] = [...obj["memories"][type], ...result.publicUrls];
        });
  
      } else if (tabMapper[activeTab] === "link") {
        obj["link"] = data.link;
      } else if (tabMapper[activeTab] === "phoneNumber") {
        obj["phoneNumber"] = data.contactNum;
      } else if (tabMapper[activeTab] === "image") {
        const uploadPromises = [];

        if (data.imageVideos?.length) {
          const newImages = data.imageVideos.filter((ele) => ele instanceof File);
          const preImages = data.imageVideos.filter((ele) => typeof ele === "string");
          if (newImages.length > 0) {
            uploadPromises.push(uploadToS3(newImages, "image", data?._id));
          }
          obj["imageVideos"] = preImages;

          const uploadResults = await Promise.all(uploadPromises);

          uploadResults.forEach((result) => {
            obj["imageVideos"] = [...obj["imageVideos"], ...result.publicUrls];
          });
        } else {
          obj["imageVideos"] = [];
        }
      }
  
      // Now that all uploads are complete, update the QR code
      await updateUserQRCodes(id, obj);
      getBarcodeData();
      toast.success("QR code updated successfully");
    } catch (error) {
      console.log("Error while updating qr code ", error);
      toast.error(
        error?.response?.data?.message || "Error while updating qr code"
      );
    } finally {
      setProcessing(false);
    }
  };

  const tabMapper = {
    1: "link",
    2: "phoneNumber",
    3: "memories",
    4: "pdf",
    5: "image",
  };

  const getBarcodeData = async () => {
    try {
      const { data } = await getPrivateQRCodes(id);
      setBarcodeData(data.barcode);
      setValue(
        "contactNum",
        data?.barcode?.storedInfo?.infoType === "phoneNumber"
          ? data?.barcode?.storedInfo?.link || ""
          : ""
      );
      setValue(
        "link",
        data?.barcode?.storedInfo?.infoType === "link"
          ? data?.barcode?.storedInfo?.link
          : ""
      );
      setValue("name", data?.barcode?.name ? data.barcode.name : "");


      Object.keys(tabMapper).forEach((key) => {
        if (tabMapper[key] === data?.barcode?.storedInfo?.infoType) {
          changeTab(parseInt(key));
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error while getting barcode data"
      );
    }
  };
  useEffect(() => {
    if (id) {
      getBarcodeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const disableButtonCondition = () => {
    let condition = false;
    if (activeTab === 3) {
      if (
        !watch("title") &&
        !watch("freeText") &&
        !watch("videoUrl") &&
        !watch("imageText") &&
        !watch("videoDescription") &&
        !watch("pdfTitle") &&
        ((watch("images") && watch("images").length === 0) ||
          !watch("images")) &&
        ((watch("pdfs") && watch("pdfs").length === 0) || !watch("pdfs")) &&
        ((watch("videos") && watch("videos").length === 0) || !watch("videos"))
      ) {
        condition = true;
      }
    }
    return condition;
  };
  return (
    <>
      <Header />
      <div className="max-w-7xl mx-auto">
        <div className="my-8 mx-0 lg:my-16 lg:mx-16">
          <Link
            to="/"
            className="flex items-center border border-black p-2 rounded-md w-fit"
          >
            <IoMdArrowRoundBack className="me-2" />
            Back
          </Link>
          <h2 className="text-3xl font-medium mb-3 text-center">
            Edit QR Code:
          </h2>
          <div className="flex flex-col px-[20px]">
            <div className="flex justify-center my-2 flex-wrap">
              <button
                className={`${
                  activeTab === 1
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 my-1 border flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(1)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <FaLink />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>Url / Link</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 2
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(2)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <IoCallOutline />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>Phone Call</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 3
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(3)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <TbGrave2 />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>Create Page</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 4
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(4)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <FaRegFilePdf />{" "}
                  </p>
                  <p className="text-[12px]">
                    <b>PDF</b>{" "}
                  </p>
                </div>
              </button>
              <button
                className={`${
                  activeTab === 5
                    ? "bg-tealBlue text-white"
                    : "bg-lightBlue text-white-700"
                } py-2 px-4 rounded mx-2 sm:mx-4 border my-1 flex-col justify-center hover:bg-tealBlue  border-borderMuted  flex  focus:outline-none`}
                onClick={() => changeTab(5)}
              >
                <div className="flex ">
                  <p className="mx-1">
                    <IoImage />
                  </p>
                  <p className="text-[12px]">
                    <b>Images/Videos</b>
                  </p>
                </div>
              </button>
            </div>
            <div className="max-w-[800px] w-full mx-auto bg-white mt-4 sm:mt-6 rounded pt-3 pb-6 sm-pb-8 max-h-[800px] overflow-y-scroll">
              <div className="tab-content p-4 rounded-md max-w-[450px] w-full mx-auto">
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`tab-pane relative`}>
                      {/* only for mobile */}
                      {window.innerWidth < 768 &&
                        tabMapper[activeTab] === "memories" && (
                          <div className="top-[0%] z-50 mb-4">
                            <button
                              type="submit"
                              disabled={disableButtonCondition()}
                              className="max-w-[350px] mx-auto flex items-center justify-center focus:outline-none  disabled:bg-gray-400 text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-3 w-full transition duration-150 ease-in"
                            >
                              <span className="mr-2">Save</span>
                            </button>
                          </div>
                        )}

                      {tabMapper[activeTab] === "link" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <input
                              {...register("link")}
                              placeholder="Put your link here"
                              className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                                errors?.link
                                  ? "border-red-500"
                                  : "border-gray-400"
                              } w-full py-3 focus:outline-none focus:border-blue-400`}
                            />
                          </div>
                          {errors?.link && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.link.message}
                            </span>
                          )}
                        </div>
                      )}

                      {tabMapper[activeTab] === "phoneNumber" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <PhoneInputWithCountry
                              id="contactNum"
                              name="contactNum"
                              defaultCountry="US"
                              control={control}
                              className={`py-3 w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                                errors?.contactNum
                                  ? "border-red-500"
                                  : "border-gray-400"
                              }`}
                            />
                          </div>
                          {errors?.contactNum && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.contactNum.message}
                            </span>
                          )}
                        </div>
                      )}

                      {tabMapper[activeTab] === "memories" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <Memories data={barcodeData} />
                          </div>
                          {errors?.contactNum && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.contactNum.message}
                            </span>
                          )}
                        </div>
                      )}

                      {tabMapper[activeTab] === "pdf" && (
                        <Pdf data={barcodeData}  getBarcodeData={getBarcodeData}/>
                      )}

                      {tabMapper[activeTab] === "image" && (
                          <>
                            <ImageVideos data={barcodeData} />
                          </>
                        )}

                      {tabMapper[activeTab] !== "memories" && (
                        <div className="flex flex-col mb-4 sm:mb-6">
                          <div className="relative">
                            <input
                              {...register("name")}
                              className={`w-full text-sm sm:text-base placeholder-gray-500 px-4 rounded-lg border ${
                                errors?.name
                                  ? "border-red-500"
                                  : "border-gray-400"
                              } w-full py-3 focus:outline-none focus:border-blue-400`}
                              placeholder="Name your QR (optional)"
                            />
                          </div>
                          {errors?.name && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors.name.message}
                            </span>
                          )}
                        </div>
                      )}

                      <div className="sticky top-[0%] z-50 mb-4">
                        <button
                          type="submit"
                          disabled={disableButtonCondition()}
                          className="max-w-[350px] mx-auto flex items-center justify-center focus:outline-none  disabled:bg-gray-400 text-white text-sm sm:text-base bg-[#0082B2] hover:bg-[#006d95] rounded py-3 w-full transition duration-150 ease-in"
                        >
                          <span className="mr-2">Save</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>

        {/* Loading State */}
        {processing ? (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
            <div
              className="h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default EditQr;
