import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPublicQRCodes } from "../api/qrcode";
import toast from "react-hot-toast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CustomPrevArrow = (props) => (
  <div
    {...props}
    className="absolute left-2 top-1/2 -translate-y-1/2 z-10 cursor-pointer bg-black bg-opacity-50 rounded-full p-1 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
  >
    <FaChevronLeft className="text-white text-lg" />
  </div>
);

const CustomNextArrow = (props) => (
  <div
    {...props}
    className="absolute right-2 top-1/2 -translate-y-1/2 z-10 cursor-pointer bg-black bg-opacity-50 rounded-full p-1 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
  >
    <FaChevronRight className="text-white text-lg" />
  </div>
);

const CustomDot = ({ onClick, ...rest }) => {
  const { index, active } = rest;
  return (
    <button
      onClick={onClick}
      className={`w-2 h-2 rounded-full ${active ? "bg-black" : "bg-gray-300"}`}
    />
  );
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  adaptiveHeight: true,
  centerMode: false,
  arrows: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  customPaging: CustomDot,
};

const ImageVideosInfoPage = () => {
  const { id } = useParams();

  const [processing, setProcessing] = useState(false);
  const [barcodeData, setBarcodeData] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);

  const getBarcodeData = async () => {
    setProcessing(true);
    try {
      const { data } = await getPublicQRCodes(id);
      setBarcodeData(data.barcode);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error while getting barcode data"
      );
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    getBarcodeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-full max-w-4xl p-4 md:p-8">
          <div className="rounded-lg">
            {barcodeData?.storedInfo?.imageVideos?.length > 0 ? (
              <div className="space-y-4 group">
                <Slider {...settings}>
                  {barcodeData.storedInfo.imageVideos.map((item, index) => (
                    <div
                      key={index}
                      className="slider-item flex justify-center items-center h-[400px]"
                    >
                      {[".mp4", ".mov", ".avi", ".wmv", ".flv", ".mkv"].some(
                        (extension) => item.endsWith(extension)
                      ) ? (
                        <video
                          className="w-full h-full object-contain"
                          controls
                          loop
                          playsInline
                          type={`video/${item.split(".").pop()}`}
                        >
                          <source src={`${item}#t=0.001`} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={item}
                          alt={`Memory ${index + 1}`}
                          className="w-full h-full object-contain cursor-pointer"
                          onClick={() => setSelectedImage(item)}
                        />
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-64 text-center px-4">
                <svg
                  className="w-16 h-16 text-gray-400 mb-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  ></path>
                </svg>
                <h3 className="text-xl font-semibold text-gray-700 mb-2">
                  No Media Added Yet
                </h3>
                <p className="text-gray-500 text-base max-w-md">
                  There are no images or videos associated with this QR code.
                  Add media to bring memories to life!
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Image Pop-out */}
        {selectedImage && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
            onClick={() => setSelectedImage(null)}
          >
            <div
              className="relative py-4 flex items-center"
              style={{
                width: "fit-content",
              }}
            >
              <div className="relative">
                <img
                  src={selectedImage}
                  alt="Enlarged view"
                  className="w-[500px] h-auto  object-cover"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedImage(null);
                  }}
                  className="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded-full text-[27px] text-xl w-7 h-7 flex items-center justify-center leading-none hover:bg-opacity-75 transition-opacity"
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Loading State */}
        {processing ? (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
            <div
              className="h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ImageVideosInfoPage;
