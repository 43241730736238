import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineClose, AiOutlineCloudUpload } from "react-icons/ai";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";

export default function ImageVideos({ data }) {
  const {
    setValue,
    watch,
  } = useFormContext();

  const [acceptedFiles, setAcceptedFiles] = useState([]);

  useEffect(() => {
    setValue("imageVideos", data?.storedInfo?.imageVideos || []);

    setAcceptedFiles(data?.storedInfo?.imageVideos|| []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveFile = (file) => {
    const updateFiles = (prevFiles) =>
      prevFiles?.filter((f) =>
        typeof f === "string" ? f !== file : f.path !== file.path
      );
    setAcceptedFiles(updateFiles);
    setValue("imageVideos", updateFiles(watch("imageVideos")));
  };

  const { getRootProps, getInputProps } =
  useDropzone({
    onDrop: async (acceptedFiles, notAcceptedFile) => {
      if (notAcceptedFile.length > 0) {
        toast.error(notAcceptedFile[0].errors[0].message);
        return;
      }
      let files = watch("imageVideos") || [];
      setValue("imageVideos", [...files, ...acceptedFiles]);
      setAcceptedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg', '.gif'],
      "video/*": [".mp4", ".mov", ".avi", ".wmv", ".flv", ".mkv"]
    },
    maxSize: 250 * 1024 * 1024,
  });

  return (
    <div className="mt-4 mb-4">
        <h4>You can upload image(s) and video(s) from your device</h4>
        <div className="flex items-center space-x-2 pt-2">
          <div
            className={`flex w-full justify-center items-center h-52 border-2 bg-blue-50 border-tealBlue border-dashed rounded-lg p-4`}
            {...getRootProps()}
          >
            <input {...getInputProps()}/>
            <div className="text-center">
              <AiOutlineCloudUpload className="mx-auto text-tealBlue text-6xl" />
              <button
                type="button"
                className="mt-4 bg-tealBlue text-white px-6 py-2 rounded-lg hover:bg-tealBlue"
              >
                Upload image(s) and video(s)
              </button>
              <p className="text-gray-600 text-sm font-bold mt-4">
                Maximum size: 250MB
              </p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {acceptedFiles.map((file) => (
            <li
              key={typeof file === "string" ? file : file.path}
              className="flex justify-between items-center text-sm"
            >
              {typeof file === "string" ? file.split("/").pop() : file.path}
              <AiOutlineClose
                className="text-red-500 cursor-pointer"
                onClick={() => handleRemoveFile(file)}
              />
            </li>
          ))}
        </div>
      </div>
  );
}
