import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { MdOutlineFileUpload } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import apiClient from "../../api";
import { uploadToS3 } from "../../utils/uploadToS3";
import { deleteStoredImage, updateUserQRCodes } from "../../api/qrcode";

const Pdf = ({ data, getBarcodeData }) => {
  const fileName = data?.storedInfo?.link?.split("/").pop();
  const initFilePreview = fileName
    ? { name: fileName, url: data?.storedInfo?.link }
    : null;

  // file upload
  const [progress, setProgress] = useState(0);
  const [filePreview, setFilePreview] = useState(initFilePreview);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    maxSize: 250 * 1024 * 1024, // 250MB
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop: async (acceptedFiles, notAcceptedFile) => {
      if (notAcceptedFile.length > 0) {
        toast.error(notAcceptedFile[0].errors[0].message);
        return;
      }

      try {
        const res = await uploadToS3(acceptedFiles, "pdfs", data?._id, (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(percentage);
        })
        await updateUserQRCodes(data?._id, {
            type: "pdf",
            link: res.publicUrls[0]
        });
        setFilePreview({ name: acceptedFiles[0].name, url: res.publicUrls[0] });
        toast.success("QR code file updated successfully");
        getBarcodeData();
      } catch (error) {
        console.log("Error while file updating qr code ", error);
        toast.error(
          error?.response?.data?.message || "Error while upload qr code"
        );
      } finally {
        setProgress(0);
      }
    },
  });

  const handleDeleteStoredImage = async (id) => {
    try {
      const data = await deleteStoredImage(id);
      if (data?.status !== 200) throw data?.data;
      setFilePreview(null);
      toast.success(
        data?.data.message || "QR code stored file deleted successfully"
      );
      return true;
    } catch (error) {
      toast.error(error.message || "Error deleting QR code Image");
    }
  };


  return (
    <div className="flex flex-col mb-4 sm:mb-6">
      {filePreview ? (
        <div className="mb-3 rounded-md flex justify-between items-center relative">
          <div className="border py-1 px-2 rounded-md flex-1 group text-ellipsis text-nowrap">
            <span className="text-ellipsis text-nowrap">
              {filePreview.name}
            </span>
            <Link
              to={filePreview.url}
              target="_blank"
              className="hidden group-hover:block bg-black py-1 px-2 rounded-md absolute top-[-100%] text-base text-white z-10"
            >
              {" "}
              {filePreview.url}
            </Link>
          </div>

          <button
            type="button"
            onClick={(e) => handleDeleteStoredImage(data?._id)}
            className="text-2xl font-medium w-[20px] h-[20px] rounded-full bg-white flex items-center justify-center mx-2"
          >
            <RiDeleteBinLine />
          </button>
        </div>
      ) : null}
      <div
        {...getRootProps({
          className:
            "dropzone inline-block w-full text-sm text-tealBlue px-4 rounded-lg border border-blue-400 w-full py-3 bg-[#e8f0fe] cursor-pointer",
        })}
      >
        <input {...getInputProps()} />
        <div className="flex items-center justify-center">
          <MdOutlineFileUpload className="mr-1 text-lg sm:text-2xl" />
          <p className="inline-block text-sm sm:text-base font-medium text-center">
            Upload PDF File
          </p>
        </div>
        {/* Progress Bar */}
        {progress > 0 && (
          <div className="w-full h-1 bg-gray-200 mt-2">
            <div
              className="h-full bg-green-500"
              style={{
                width: `${progress > 100 ? 100 : progress}%`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Pdf;
