import axios from "axios";
import apiClient from "../api";
import toast from "react-hot-toast";

export const uploadToS3 = async (files, folderPath, id, onUploadProgress) => {
    try {
      const { data } = await apiClient().post(`/barcode/files/${id}`, {
        files: files.map((file) => ({ name: file.name, type: file.type })),
        folderPath: folderPath,
      });
  
      const uploadPromises = data.data.map((file, index) =>
        axios.put(file.presignedUrl, files[index], {
          headers: {
            "Content-Type": files[index].type,
          },
          onUploadProgress: (progressEvent) => {
            if (onUploadProgress) {
              onUploadProgress(progressEvent);
            }
          },
        })
      );
  
      // Wait for all uploads to finish
      await Promise.all(uploadPromises);
  
      return {
        publicUrls: data.data.map((ele) => ele.publicUrl),
      };
    } catch (error) {
      console.error("Error uploading to S3:", error);
      toast.error(error?.response?.data?.message || "Upload failed");
      throw error;  // Re-throw the error to handle it in the onSubmit
    }
  };